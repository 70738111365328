import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios'
import EventStatus from './views/EventStatus';
import ViewEvent from './views/ViewEvent';
import Button from '@mui/material/Button';
import Heading from './Heading';
import { useLocation,Link,useNavigate } from 'react-router-dom'
import EventCreater from './views/EventCreater';

const baseUrl = '/api/'

const AppEditBus = (props) => {
    const navigate = useNavigate();
    const [mainInfo, setMainInfo] = useState({})
    const [studentsInfo, setStudentsInfo] = useState([])
    const [dopInfo, setDopInfo] = useState([])

    const accept = () => {
        const object = {id: events_id,
                        result: true,
                        comment: ''}
        axios.post('/api/edit/coordbus/' + events_id, object).then((result) => {
            if(result.status === 200){
                alert('Заявка согласована')
                navigate("/app/coordzam")
            }
        })
    }

    const decline = () => {
        const comment = prompt("Укажите причину отказа");
        const object = {id: events_id,
                        result: false,
                        comment: comment}
        axios.post('/api/edit/coordbus/' + events_id, object).then((result) => {
            if(result.status === 200){
                alert('Заявка отклонена')
                navigate("/app/coordzam")
            }
        })
    }



    useEffect(() => {getInformation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInformation = () => {
        axios.get(baseUrl + 'events/id/' + events_id).then((res) => {
            setMainInfo(res.data[0])
            setStudentsInfo(JSON.parse(res.data[0].students))
            setDopInfo(JSON.parse(res.data[0].helpers))
        })
    }

    const location = useLocation()
    const { events_id } = location.state

    return(
        <div >
            <Heading text={"Заявка № " + events_id}/>
            <div className='headBlock'>
                <EventStatus workflowStep={mainInfo.workflowStep} />
                <ViewEvent mainInfo={mainInfo} students={studentsInfo} helpers={dopInfo} dateInDayJs={true}/>
                <EventCreater userCreated={mainInfo.usercreated} userEdited={mainInfo.useredited}/>

                <div className='buttonRow'>
                    <Link to='/app/'>
                        <Button variant="contained" color="success" >Назад</Button>
                    </Link>
                    <div>
                        <Button variant="contained" color="error" onClick={() => decline()} >Отклонить</Button>
                        <Button variant="contained" color="success" onClick={() => accept()}>Согласовать</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppEditBus;
